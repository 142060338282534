<template>
  <v-app style="max-width: 720px;" class="px-0 mx-auto">    
    
    <v-app-bar app absolute elevation="2" color="primary" dark elevate-on-scroll fixed>
      <v-app-bar-nav-icon></v-app-bar-nav-icon>
      <v-toolbar-title>Обработчики</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main elevation="4">

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
          rounded="0"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Редактирование</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              @click="dialog = false"
            >
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <!-- <div class="v-subheader theme--light">Общая информация</div> -->
        <div class="text-h6 mt-4 ml-4">Общая информация</div>
        <v-form class="pa-4 pt-0">
            <!-- :label="editHandler.enable ? 'Обработчик включен' : 'Обработчик отключен'" -->
          <v-switch
            v-model="editHandler.enable"
            label="Вкл/выкл обработчик"
            @change="changeEnable(handler.id)"
          ></v-switch>
            <!-- @change="editHandler.enable = !editHandler.enable" -->
          <v-text-field
            v-model="editHandler.name"
            label="Название"
          ></v-text-field>
          <v-text-field
            v-model="editHandler.description"
            label="Описание"
          ></v-text-field>
        </v-form>

        <div class="d-flex">
          <!-- <div class="v-subheader theme--light">Триггеры</div> -->
          <div class="text-h6 ml-4">Триггеры</div>
          <div class="ml-auto d-flex align-center">
            <v-btn text small class="mr-4" @click="addTrigger">
              Добавить
            </v-btn>
          </div>
        </div>
        
        <!-- <div class="v-subheader theme--light">Настройка выполнения</div> -->
        <!-- <div class="v-header theme--light ml-4">Триггеры</div> -->
        <v-form class="pa-4 pt-0 mb-6">
          <template
            v-for="trigger in editHandler.triggers"
          >
            <v-col cols="12" class="row mx-0 px-0" :key="trigger.id">
              <v-col cols="12" class="py-0 pl-0">
                <div class="d-flex" :key="trigger.id">
                  <v-autocomplete
                    v-model="trigger.type"
                    :items="triggersItems"
                    label="Событие"
                    hide-details
                  ></v-autocomplete>
                  <div class="ml-auto d-flex align-end">
                    <v-btn
                      class="ml-2"
                      icon
                      small
                      @click="deleteTrigger(trigger.id)"
                    >
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-col>
            
          </template>
        </v-form>

        <div class="d-flex">
          <!-- <div class="v-subheader theme--light">Условия</div> -->
          <div class="text-h6 ml-4">Условия</div>
          <div class="ml-auto d-flex align-center">
            <v-btn text small class="mr-4" @click="addCondition">
              Добавить
            </v-btn>
          </div>
        </div>
        <v-form class="pa-4 pt-0 mb-6">
          <template
            v-for="condition in editHandler.conditions"
          >
            <v-col cols="12" class="row mx-0 px-0" :key="condition.param">
              <v-col cols="4" class="py-0 pl-0">
                <v-autocomplete
                  v-model="condition.param"
                  :items="paramItems"
                  label="Параметр"
                  hide-details
                ></v-autocomplete>
              </v-col>

              <v-col cols="4" class="py-0 pl-0">
                <v-autocomplete
                  v-model="condition.equal"
                  :items="equalItems"
                  label="Сравнение"
                  hide-details
                ></v-autocomplete>
              </v-col>

              <v-col cols="4" class="py-0 pl-0">
                <div class="d-flex">
                  <v-text-field 
                    v-model="condition.value"
                    label="Значение"
                    hide-details
                  ></v-text-field>

                  <div class="ml-auto d-flex align-end">
                    <v-btn
                      class="ml-2"
                      icon
                      small
                      @click="deleteCondition(condition.id)"
                    >
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-col>
          </template>
        </v-form>

        <div class="d-flex">
          <!-- <div class="v-subheader theme--light">Действия</div> -->
          <div class="text-h6 ml-4">Действия</div>
          <div class="ml-auto d-flex align-center">
            <v-btn text small class="mr-4" @click="addAction">
              Добавить
            </v-btn>
          </div>
        </div>
        <v-form class="pa-4 pt-0 mb-6">
          <template
            v-for="action in editHandler.actions"
          >
            <div class="d-flex" :key="action.id">
              <v-autocomplete
                v-model="action.service"
                :items="serviceItems"
                label="Выполнить"
              ></v-autocomplete>
              <div class="ml-auto d-flex align-center">
                <v-btn
                  class="ml-2"
                  icon
                  small
                  @click="deleteAction(action.id)"
                >
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </div>
            </div>
            <v-textarea
              :key="action.id"
              v-model="action.params"
              label="Параметры действия"
              outlined
            ></v-textarea>
          </template>
        </v-form>

        <v-list
          three-line
          subheader
        >
        </v-list>
      </v-card>
    </v-dialog>

      <!-- <v-container> -->
        <!-- <v-col> -->
          <!-- <v-card style="heigth: 100%">123

          <v-btn
            fab
            large
            dark
            bottom
            right
            class="v-btn--example"
            @click="dialog = !dialog"
          >
            <v-icon>mdi-share-variant</v-icon>
          </v-btn>

          </v-card> -->

        <!-- </v-col> -->
      <!-- </v-container> -->

      <v-container
        class=""
        fluid
        rounded="0"
      >
        <v-row>
          <v-col
            cols="12"
            class="pa-0"
          >
            <v-card>
              <!-- <v-subheader>{{ card }}</v-subheader> -->

              <v-list two-line class="py-0" >
                <template v-for="handler in handlers">
                  <v-list-item
                    :key="handler.id"
                    @click="openEditDialog(handler.id)"
                  >
                    <v-list-item-avatar :color="handler.enable ? 'primary' : 'grey darken-1'">
                      <v-icon dark>
                        mdi-robot
                      </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>{{ handler.name }}</v-list-item-title>

                      <v-list-item-subtitle>
                        {{ handler.description }}
                      </v-list-item-subtitle>

                    </v-list-item-content>
                  </v-list-item>

                  <v-divider
                    :key="handler.name"
                  ></v-divider>
                </template>

                <!-- <v-btn
                  fab
                  large
                  dark
                  bottom
                  right
                  class="v-btn--example"
                  @click="dialog = !dialog"
                >
                  <v-icon>mdi-share-variant</v-icon>
                </v-btn> -->
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-container>


    </v-main>
  </v-app>


  <!-- <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      app
    >
 
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Application</v-toolbar-title>
    </v-app-bar>

    <v-main>

      123
    </v-main>
  </v-app> -->

</template>



<script>
import HelloWorld from './components/HelloWorld';

export default {
  name: 'App',

  components: {
    HelloWorld,
  },

  data: () => ({
    dialog: false,

    handlers: [
      { 
        id: 1, 
        enable: true,
        name: 'Обработчик 1',
        description: 'Открытие шлагбаума для сохраненных номеров',
        triggers: [
          { id: 't1', type: 'car_plate_found', },
        ],
        conditions: [
          { id: 'c1', param: 'plate', equal: 'in', value: 'list.whitelist', },
        ],
        actions: [
          { id: 'a1', service: 'webhook', params: '{\n  "url": "http://google.com",\n  "params": "1234578"\n}', }
        ]
      },
      { 
        id: 2, 
        enable: true,
        name: 'Обработчик 2',
        description: 'Уведомление при обнаружении номера A123BC77 на первой камере',
        triggers: [
          { id: 't1', type: 'car_plate_found', },
        ],
        conditions: [
          { id: 'c1', param: 'source', equal: 'equal', value: 'Камера 1', },
          { id: 'c2', param: 'plate', equal: 'equal', value: 'A123BC77', },
        ],
        actions: [
          { id: 'a1', service: 'telegram', params: '{\n  "chat_id": 12345678,\n  "text": "Приехал A123BC77"\n}', }
        ]
      },
      { 
        id: 3, 
        enable: false,
        name: 'Обработчик 3',
        description: 'Пустой и отключенный обработчик',
        triggers: [],
        conditions: [],
        actions: []
      },

    ],

    editHandler: {
      name: '',
      description: '',
      triggers: [
        { id: 't1', type: 'car_plate_found', },
      ],
      conditions: [
        { id: 'c1', param: 'source', equal: 'equal', value: 'Камера 1', },
        { id: 'c2', param: 'plate', equal: 'equal', value: 'A123BC77', },
      ],
      actions: [
        { id: 'a1', service: 'telegram', params: '', }
      ]
    },

    triggersItems: [
      { text: 'Распознан номер', value: 'car_plate_found' },
      { text: 'Обранужена спецтехника', value: 'special_car_found' },
      { text: 'Обранужен легковой автомобить', value: 'default_car_found' },
      { text: 'Обранужен мотоцикл', value: 'moto_car_found' },
    ],

    paramItems: [
        { text: 'Номер', value: 'plate' },
        { text: 'Источник', value: 'source' },
        { text: 'Время', value: 'time' },
        { text: 'День недели', value: 'weekday' },
    ],

    equalItems: [
      { text: 'содержит', value: 'contains' },
      { text: 'находится в', value: 'in' },
      { text: 'равно', value: 'equal' },
      { text: 'не равно', value: 'not equal' },
      { text: 'больше', value: 'more' },
      { text: 'меньше', value: 'less' },
      { text: 'regex match', value: 'match' },
    ],

    serviceItems: [
      { text: 'Отправить вебхук', value: 'webhook' },
      { text: 'Уведомление в телеграм', value: 'telegram' },
    ],

    cards: ['Today', 'Yesterday'],
  }),

  methods: {
    openEditDialog(handlerId) {
      this.editHandler = this.handlers.find(h => h.id == handlerId);
      // this.editHandler = this.handlers.filter(h => h.id == handlerId)[0];
      this.dialog = true;
    },

    changeEnable(handlerId) {
      let handler = this.handlers.find(h => h.id == handlerId);
      if (handler.enable) {
        handler.enable = false;
      }
      else {
        handler.enable = true;
      }
    },

    enableHandler(handlerId) {
      this.handlers.find(h => h.id == handlerId).enable = true;
    },

    disableHandler(handlerId) {
      this.handlers.find(h => h.id == handlerId).enable = false;
    },

    addTrigger() {
      this.editHandler.triggers.push({id: 't' + new Date().getTime(), type: null});
    },

    deleteTrigger(triggerId) {
      this.editHandler.triggers = 
        this.editHandler.triggers.filter(t => t.id != triggerId);
    },

    addCondition() {
      this.editHandler.conditions.push({
        id: 'c' + new Date().getTime(),
        param: null,
        equal: null,
        value: null,
      });
    },

    deleteCondition(conditionId) {
      this.editHandler.conditions = 
        this.editHandler.conditions.filter(c => c.id != conditionId);
    },

    addAction() {
      this.editHandler.actions.push({
        id: 'a' + new Date().getTime(),
        service: null,
        params: null,
      });
    },

    deleteAction(actionId) {
      this.editHandler.actions = 
        this.editHandler.actions.filter(a => a.id != actionId);
    },

  },
};
</script>

<style>
.v-application {
  margin: 0 auto 0 auto;
}

.v-dialog {
    max-width: 720px;
    margin: 0 auto 0 auto;
    position: unset;
}
</style>
